import React from "react"
import "./src/styles/index.scss"
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyC7tqvh0zkqmWaoZ_RiWKcfD3y_epXlpiI",
//   authDomain: "bodateteyleito-11201.firebaseapp.com",
//   projectId: "bodateteyleito-11201",
//   storageBucket: "bodateteyleito-11201.appspot.com",
//   messagingSenderId: "893209073614",
//   appId: "1:893209073614:web:1157663509d45e0b817d9f"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
export const wrapRootElement = ({ element }) => element
